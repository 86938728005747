<template>
  <div id="app">
    <ThemeAdd v-if="!id && !isLoaded" ref="simpleRules" :key="$route.fullPath" :is-loaded="isLoaded" :theme-details="themeDetails" @on-Submit="createTheme" />
    <ThemeAdd v-if="id && isLoaded" ref="simpleRules" :key="$route.fullPath" :is-loaded="isLoaded" :theme-details="themeDetails" @on-Submit="updateTheme" />

  </div>
</template>

<script>
import checkLoginRole from '@/helpers/checkLoginRole'
import themeService from '@/services/banner-management/theme/theme.service'
import { resConditionCheck, ImageError } from '@/helpers/constant'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import errorResponseHandler from '@/services/errorHandler'

import ThemeAdd from './ThemeAdd.vue'

export default {
  name: 'ThemeAddEdit',
  components: { ThemeAdd },
  data() {
    return {
      isLoaded: false,
      themeDetails: {},
      id: this.$route.params.id ? this.$route.params.id : '',
      checkAccess: {},
      checkLoginRole

    }
  },
  watch: {
    async $route() {
      if (this.$route.name === 'theme-add') {
        this.id = ''
        this.themeDetails = {}
        this.isLoaded = false
      } else if (this.$route.name === 'theme-edit') {
        this.id = this.$route.params.id
        this.isLoaded = true
      }
    }
  },

  async mounted() {
    if (this.$route.params.id) {
      await this.getThemeById(this.$route.params.id)
    }
  },

  methods: {
    async createTheme(themeAddForm) {
      const valid = await this.$refs.simpleRules.validationForm()
      if (!themeAddForm.image) {
        this.$refs.simpleRules.loading = true
        setTimeout(() => {
          this.$refs.simpleRules.loading = false
        }, 3000)

        this.$toast({
          component: ToastificationContent,
          props: {
            title: ImageError.Theme_Image_Error,
            icon: "X-CircleIcon",
            variant: "danger",
          },
        });
      }
      if (valid && themeAddForm.image) {
        const formdata = new FormData()
        formdata.append('name', themeAddForm.name)
        formdata.append('slug', themeAddForm.slug)
        formdata.append('is_active', themeAddForm.is_active)
        formdata.append('image', themeAddForm.image, themeAddForm.image.name)
        this.$refs.simpleRules.loading = true
        await themeService.addTheme(formdata).then(res => {
          this.$refs.simpleRules.loading = false
          if (resConditionCheck(res.status) && res.data.data) {
            this.$router.push({ name: 'theme-list' })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "CheckIcon",
                variant: "success",
              },
            });
          }
        }).catch(error => {
          this.$refs.simpleRules.loading = false
          const errorData = errorResponseHandler(error)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: errorData,
              icon: "X-CircleIcon",
              variant: "danger",
            },
          });
        })
      }
    },

    async getThemeById(id) {
      await themeService.getThemeById(id).then(res => {
        this.isLoaded = true;
        if (resConditionCheck(res.status) && res.data.data) {
          this.themeDetails = res.data.data
        }
      }).catch(error => {
        const errorData = errorResponseHandler(error)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: errorData,
            icon: "X-CircleIcon",
            variant: "danger",
          },
        });
      })
    },

    async updateTheme(themeAddForm) {
      const valid = await this.$refs.simpleRules.validationForm()
      if (!themeAddForm.image) {
        this.$refs.simpleRules.loading = true
        setTimeout(() => {
          this.$refs.simpleRules.loading = false
        }, 3000)

        this.$toast({
          component: ToastificationContent,
          props: {
            title: ImageError.Theme_Image_Error,
            icon: "X-CircleIcon",
            variant: "danger",
          },
        });
      }
      if (valid && themeAddForm.image) {
        const formdata = new FormData()
        formdata.append('name', themeAddForm.name)
        formdata.append('slug', themeAddForm.slug)
        formdata.append('is_active', themeAddForm.is_active)
        formdata.append('image', themeAddForm.image)
        this.$refs.simpleRules.loading = true

        themeService.editTheme(this.$route.params.id, formdata).then(res => {
          this.$refs.simpleRules.loading = false
          if (resConditionCheck(res.status) && res.data.data) {
            this.$router.push('/theme/list')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "CheckIcon",
                variant: "success",
              },
            });
          }
        }).catch(error => {
          this.$refs.simpleRules.loading = false
          const errorData = errorResponseHandler(error)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: errorData,
              icon: "X-CircleIcon",
              variant: "danger",
            },
          });
        })
      }
    }
  }
}

</script>
