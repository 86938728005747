<template>
  <b-card-code>
    <!-- forms -->
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="$emit('on-Submit', themeAddForm)">
        <b-row col>
          <b-col md="6">
            <b-form-group label="Theme Name" label-for="mc-theme-name">
              <validation-provider
                #default="{ errors }"
                name="Theme Name"
                rules="required|max:40|min:3"
              >
                <b-form-input
                  v-model="themeAddForm.name"
                  class="theme_name"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Theme Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Theme Slug" label-for="theme_slug">
              <validation-provider
                #default="{ errors }"
                name="Theme Slug"
              rules="required|max:40|min:3|noWhiteSpace"
              >
                <b-form-input
                  v-model="themeAddForm.slug"
                  class="theme_slug"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Theme Slug"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row col>
          <b-col md="7" class="mt-2">
            <div class="helper" />
            <div class="drop align-center" @dragover.prevent @drop="onDrop">
              <div class="helper" />
              <label
                v-if="!imagePreview && !themeDetails.image"
                class="btn btn-primary"
              >
                SELECT OR DROP AN IMAGE
                <input
                  type="file"
                  accept="image/*"
                  name="image"
                  @change="onChange"
                >
              </label>
              <div
                v-if="imagePreview"
                class=" display-inline align-center"
                :class="{ image: true }"
              >
                <img :src="imagePreview" alt="Image" class="img mt-2">
                <br>
                <br>
                <button class="btn btn-primary mb-2" @click="removeFile">
                  REMOVE
                </button>
              </div>
              <div
                v-if="themeDetails.image && !imagePreview && $route.params.id"
                class=" display-inline align-center"
                :class="{ image: true }"
              >
                <img :src="themeDetails.image" alt="Image" class="img mt-2">
                <br>
                <br>
                <button class="btn btn-primary mb-2" @click="removeFile">
                  REMOVE
                </button>
              </div>
            </div>
            <b-alert class="mt-2" variant="primary" show>
              <div class="alert-body">
                <span>Allowed JPG, JPEG, GIF or PNG. Max size of 1MB.</span>
              </div>
            </b-alert>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6">
            <b-form-group
              style="display:flex"
              label="Is Active"
              label-for="mc-isactive"
              class="mt-2"
            >
              <b-form-checkbox
                v-model="themeAddForm.is_active"
                class="is_active ml-75"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <!-- submit button -->
        <b-col md="12" class="demo-inline-spacing">
          <b-button :disabled="loading" variant="primary" type="submit">
            Submit
            <div v-if="loading" class="spinner-border spinner-border-sm" />
          </b-button>
          <b-button
            variant="secondary"
            :to="{
              name:
                checkLoginRole() === RoleEnum.SuperAdmin || checkAccess.view
                  ? 'theme-list'
                  : 'dashboard'
            }"
          >
            Cancel
          </b-button>
        </b-col>
        <!-- </b-row> -->
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormCheckbox,
  BAlert
} from "bootstrap-vue";
import { required, alpha } from "@core/utils/validations/validations";
import checkLoginRole from "@/helpers/checkLoginRole";
import { RoleEnum } from "@/helpers/constant";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import accessRightCheck from "@/helpers/accessRightCheck";

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BAlert,
    // BFormCheckbox,
    // BMediaBody,
    BFormCheckbox
  },

  props: {
    themeDetails: {
      type: Object,
      required: true
    },
    isLoaded: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      themeAddForm: {
        name: this.themeDetails?.name || "",
        slug: this.themeDetails?.slug || "",
        is_active: this.themeDetails?.isActive,
        image: this.themeDetails?.image || ""
      },
      required,
      alpha,
      checkLoginRole,
      RoleEnum,
      loading: false,
      profileFile: null,
      selectedFile: null,
      staticLink: "",
      imagePreview: "",
      file: "",
      checkAccess: {}
    };
  },

  mounted() {
    if (!this.$route.params.id) {
      this.themeAddForm.is_active = true;
    }
    this.checkAccess = accessRightCheck(this.$route.meta.module.name);
  },

  methods: {
    onDrop(e) {
      e.stopPropagation();
      e.preventDefault();
      const { files } = e.dataTransfer;
      this.createFile(files[0]);
    },

    onChange(e) {
      const { files } = e.target;
      this.createFile(files[0]);
    },

    createFile(file) {
      if (
        (file.type === "image/jpeg"
          || file.type === "image/jpg"
          || file.type === "image/png"
          || file.type === "image/gif")
        && file.size <= 1048576
      ) {
        this.themeAddForm.image = file;
        this.imagePreview = URL.createObjectURL(file, file.name);
      } else {
        this.themeAddForm.image = null;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Only JPG, JPEG, GIF or PNG Allowed with Max Size Of 1 MB",
            icon: "X-CircleIcon",
            variant: "danger"
          }
        });
      }
    },

    removeFile() {
      if (this.themeDetails.image) {
        this.themeDetails.image = "";
        this.themeAddForm.image = "";
      } else {
        this.imagePreview = "";
      }
    },

    async validationForm() {
      let valid = null;
      await this.$refs.simpleRules.validate().then(success => {
        if (success) {
          valid = true;
        } else {
          valid = false;
        }
      });
      return valid;
    }
  }
};
</script>
<style lang="scss">
.dropBtn {
  background-color: #d3394c;
  border: 0;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  padding: 15px 35px;
  position: relative;
}

.dropBtn:hover {
  background-color: #722040;
}

input[type="file"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.align-center {
  text-align: center;
}

// .helper {
//   height: 100%;
//   display: inline-block;
//   vertical-align: middle;
//   width: 0;
// }

.hidden {
  display: none !important;
}

.hidden.image {
  display: inline-block !important;
}

.display-inline {
  display: inline-block;
  vertical-align: middle;
}

.img {
  border: 1px solid #f6f6f6;
  display: inline-block;
  width: 400px;
  height: 250px;
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  padding: 0 10px;
}

.drop {
  background-color: #f2f2f2;
  border: 4px dashed #ccc;
  background-color: #f6f6f6;
  border-radius: 2px;
  height: 400px;
  width: 600px;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}
</style>

<style>
.fileUpload {
  position: relative;
  overflow: hidden;
  margin: 10px;
}
.fileUpload input.upload {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}
</style>
